<template>
    <div :class="{'previewCont': true, [stylesheetName]: true}">
        <div class="headerPanel">
            <div class='headerTitle'>
                <span>收入统计</span>
                <div class="chooseYear" @click="isShow=true">
                    <span>{{selectYear}}年</span>
                    <img src='../../assets/calendar.png' alt="" class="calendarIcon">
                </div>
            </div>

            <div class='amountPanel'>
                <div class='amount'>
                    <div class='number'>{{formatNumber(totalActualAmout)}}</div>
                    <span class='title'>实发工资</span>
                </div>
            </div>
        </div>

        <div class='contentPanel'>
            <div class="scrollPanel" v-if='salaryList.length'>
                <div class='salary_item' v-for="(item, index) in salaryList" :index="index"
                     @click="goInfo(item.detailNo, item.title)" :key='index'>
                    <div class='salary_title'>{{item.title}}</div>
                    <div class='salary_content'>
                        <span>实发工资：{{formatNumber(item.actualAmout)}}</span>
                    </div>
                </div>
            </div>

            <div class="ant-empty ant-empty-normal" v-else>
                <div class="ant-empty-image">
                    <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fillRule="evenodd"><ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse><g fillRule="nonzero" stroke="#D9D9D9"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path></g></g></svg>
                </div>
                <p class="ant-empty-description">暂无数据</p>
            </div>
        </div>
        
        <!--<div class='footerPanel' v-if='salaryList.length'>
            <a-button class='button' @click='exportAction' :loading='exporting'>导出工资条</a-button>
        </div>-->

        <div :class="{chooseYearPanel: true, show: isShow}">
            <div class="yearPanel">
                <div v-for="year in yearList" :key="year" :class="{yearItem: true, current: selectYear===year}"
                     @click="changeYear(year)">{{year}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Scroll from '@/components/tools/scroll';
    import {postAction} from '@/api/manage';
    import {getLocalStore, setUserInfo, getUserInfo, getCurrentCompany} from '@/utils/storage';
    import {trans_money1} from '@/utils/util'

    export default {
        data() {
            return {
                stylesheetName: 'default',
                salaryList: [],
                nextPageList: [],
                counter: 1, //默认已经显示出15条数据 count等于一是让从16条开始加载
                num: 5,  // 一次显示多少条
                pageStart: 0, // 开始页数
                pageEnd: 0, // 结束页数
                listData: [], // 下拉更新数据存放数组
                downData: [],  // 上拉更多的数据存放数组
                userInfo: {},
                yearList: [],
                selectYear: new Date().getFullYear(),
                isShow: false,
                totalGrossAmout: '',
                totalActualAmout: '',
                exporting: false,
                currentDetailNo:''
            }
        },
        components: {
            'v-scroll': Scroll
        },
        methods: {
            getList() {
                let postData = {
                    employeeId: this.userInfo.employeeId,
                    employeeName: this.userInfo.employeeName,
                    year: this.selectYear,
                    unionid: this.userInfo.unionid,
                    mpOpenid: this.userInfo.mpOpenid
                    
                };
                let channelLogin = getLocalStore(getLocalStore('hostname') + '_channelLogin') == '01'
                postAction(channelLogin ? '/payrollrecord/querySalary' : '/payrollrecord/query', postData, {
                    'contentType': 'application/x-www-form-urlencoded'
                }).then((res) => {
                    if (res.success) {
                        this.totalGrossAmout = res.data.totalGrossAmout;
                        this.totalActualAmout = res.data.totalActualAmout;
                        this.salaryList = res.data.listSlip;
                    } else {
                        this.totalGrossAmout = '';
                        this.totalActualAmout = '';
                        this.salaryList = [];
                        if (res.code === '104009') {
                            if (this.userInfo.hasSetPwd) {
                                // 查询密码超时
                                this.userInfo.hasChecked = false;
                                setUserInfo(this.userInfo);

                                let query = Object.assign({method: 'check', backPath: '/salaryList'}, this.$route.query, this.queryObj || {});
                                this.$router.push({
                                    path: '/checkPwd',
                                    query: query
                                });
                            } else {
                                this.$message.warning(res.message);
                            }
                        }
                    }
                });
            },
            onRefresh(done) {
                this.getList();
                // 刷新完成
                done();
            },
            onInfinite(done) {
                console.log('more');
                let nextList = [];
                this.counter++;
                this.pageEnd = this.num * this.counter;
                this.pageStart = this.pageEnd - this.num;

                if (this.counter >= 3) {
                    return false;
                }

                let i = this.pageStart;
                let end = this.pageEnd;
                for (i; i < end; i++) {
                    nextList.push({slipTitle: '2020年' + i + '月份工资条', shifa: 9000, yingfa: 8000, baoxian: 200});
                }

                this.salaryList = this.salaryList.concat(nextList);
                // 加载更多完成
                done();
            },
            initYearList() {
                this.yearList = [];
                let current = new Date().getFullYear() ; 
                for (var year = current; year > current - 10; year--) {
                    this.yearList.push(year);
                }
            },
            goInfo(id, title) {
                if (id && title) {
                    this.$router.push({
                        path: '/salary',
                        query: {
                            detailNo: id,
                            title: title.toString()
                        }
                    });
                }
            },
            changeYear(year) {
                this.selectYear = year;
                this.isShow = false;
                this.getList();
            },
            checkPwdSetting() {
                switch (this.userInfo.isusePassword) {
                    case '01':
                        let isChecked = !!this.userInfo.hasChecked || (this.$route.query.checked === 'checked');
                        if (isChecked) {
                            this.getList();
                        } else {
                            let query = Object.assign({method: 'check', backPath: '/salaryList'}, this.$route.query || this.queryObj);
                            this.$router.push({
                                path: '/checkPwd',
                                query: query
                            });
                        }
                        break;
                    case '00':
                        this.getList();
                        break;
                    default:
                        this.getList();
                        break;
                }
            },
            exportAction() {
                if (this.exporting) {
                    return false;
                }
                this.exporting = true;
                postAction('/salary/mine/export', {
                    employeeId: this.userInfo.employeeId,
                    companyId: this.currentCompany.companyId,
                    year: this.selectYear,
                }).then((res) => {
                    this.exporting = false;
                    if (res.success) {
                        this.$message.success('导出成功');
                    } else {
                        this.$message.warning(res.message);
                    }
                }).catch((err) => {
                    this.exporting = false;
                    this.$message.warning(err.message);
                })
            },
            formatNumber(num) {
                return trans_money1(num);
            }
        },
        mounted() {
            this.userInfo = getUserInfo() || {};
            this.currentCompany = getCurrentCompany() || {};
            this.currentDetailNo = this.$route.query.detailNo;

            this.initYearList();

            this.userInfo.isusePassword = this.userInfo.isusePassword || this.currentCompany.passwordSwitch;
            this.userInfo.hasSetPwd = this.userInfo.hasSetPwd || false;

            this.getList();
            
            if(this.currentDetailNo){//从通知进来
                if (this.userInfo.isusePassword === '01') { // 需要密码查询
                // 首先查询是否设置过密码
                if (this.userInfo.hasSetPwd) {
                    this.checkPwdSetting();
                }
                } else {
                    // 不需要密码
                    this.checkPwdSetting();
                }
            }
            
        }
    }
</script>
<style lang="less" scoped>
    @import "salaryList";
    .contentPanel {
        height: calc(100% - 256px)
    }
</style>